import styled from 'styled-components'

export const FriendSummaryWrapper = styled.div`

border: 1px solid black;
border-radius: 10px;
margin: 20px;
section{
  display: flex;
  flex-direction: row;
  justify-content: space-around;



}


`