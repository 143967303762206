import styled from 'styled-components'

export const AppWrapper= styled.div`

@media all and (min-width: 700px){
    max-width: 700px;
    display: flex;
    flex-direction:column;
    margin: 0 20%
}
`