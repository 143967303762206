import styled from 'styled-components'


export const Wrapper = styled.div`
font-family: Helvetica,
h1{
  
  text-align: center
}
li{
  text-align: left;
}
text-align: center;
display:flex;
flex-direction: column; 
margin: 20px;
button{
  border-radius: 10px;
}

`